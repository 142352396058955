<template>
    <div>

        <div v-if="properties!==null" >
        <h4 class="facility-info__title" >{{$t('leak_monitoring.info.title')}}</h4>
            <b-tabs class="popup_tab_page" v-if="properties!==null" align="center">
              <b-tab :title="$t('facility_info.properties')" active>
                <b-row >
                    <b-col style="height: 280px">
                        <b-row >
                            <audio controls preload="auto" :src="aurl" style = "width:70%;transform:scale(0.7);    margin-left: -40px;"/>
                            <div class="status leak" style="    margin-top: 10px;" >
                                <input type="radio" v-model="vchart" value="0" id="fixed__" checked>
                                <label for="fixed__" class="facility__label-radio">{{$t('leakage_info.fixed')}}</label>
                                <input type="radio" v-model="vchart" value="1" id="proportional__">
                                <label for="proportional__" class="facility__label-radio">{{$t('leakage_info.proportional')}}</label>
                            </div>
                        </b-row>
                        <FFTChart1 :url="fft_url"  ref="fftchart" :height="fftchart_height1"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.no')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.m2_point_id')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['m2_point_id']"  disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.m2_point_name')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['point_name']"  disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.p_type')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['point_type']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.mea_time')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['measure_datetime']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.table_column.strength')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['wave_energy']" disabled ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.table_column.max_fre')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['wave_max_frequency']" disabled ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.ai')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['ai_leak_result']" disabled ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.leak')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-select class="facility__input"  style="margin-bottom:5px;" size="sm" v-model="properties['leak_result']" :options="leak_result_option"  ></b-select>
                    </b-col>
                </b-row>
                <!--
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">device_temperature</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['device_temperature']" disabled  ></b-input>
                    </b-col>
                </b-row>
                -->
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.wave_f_id')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['wav_file_path']" disabled  ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.fft_f_id')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['fft_file_path']"   ></b-input>
                    </b-col>
                </b-row>
              </b-tab>     
            </b-tabs>
            <!--</div> -->
          <b-row class="facility-info-footer-row m-0 mb-3">
            <b-col sm="6">
                 <b-button @click="close();" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
               </b-col>
         </b-row>
        </div>
    </div>
</template>

<script>
//import WaveSurfer from 'wavesurfer.js';
import FFTChart1 from '@/components/chart/FFTChart1';
//import FFTChart1_c from '@/components/FFTChart1_c';

export default {
    name: 'DlgLeakageInfo',
    props: {
    },
    data() {
        return {
            properties: null,
       //     waveform_height1: 10,
            fftchart_height1: 180,
            is_playing: false,
       //     wavesurfer: null,
            loading:false,
            fft_url: '',
            aurl : '' ,
             img1:'',
             img2:'',
             img3:'',
             vchart : '0',
             is_dataset_option: [
                { value: '1', text : 'Dataset' },
                { value: '0', text: 'No Dataset' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
             
            ],
            leak_result_option: [
                { value: 'RT_LEAK', text: 'Leak' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
                { value: '', text : 'No Leak' },
            ],
             img_list: [],
             ai_result:'',
        }
    },
 
     watch: {
        vchart() {
               //     if(this.vchart==='0') {
                //        alert("radio button : " + this.vchart) ;
                 //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
               //     }
               var c_url = this.fft_url.split('#') ;
               this.fft_url = c_url[0]+'#'+this.vchart ;
        }
    },
    methods: {
        handleOk() {

        },
       get_ai_result(){
            this.loading = true     
            this.$http.get(this.URL+'api/set_leak_AI_data?idx='+this.properties['id']).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.ai_result = res.data.msg ;
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 10000)    
        },
        close(){
             this.properties = null;
             window.close();
        },

        show(idx) {
             this.properties = {} ;
             this.img_list =[];
             this.$http.get(this.URL+'api/get_m2_leak_info?id='+idx).then((res) => {
                if(res.data.length<=0) return

              //  let leak_result = res.data.list[0]['leak_result'] ;

             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;

            
                this.ai_result = this.properties['ai_leak_result'] ;
                this.properties_bak = Object.assign({}, this.properties)

             })


            this.$refs.modal.show();
            /*
            this.wavesurfer = WaveSurfer.create({
                container: '#waveform_m',
                waveColor: 'blue',
                progressColor: 'black',
                autoCenter: true,
        //      height: this.waveform_height1,
                plugins: [
                ]
            });
            this.wavesurfer.on('finish', () => {
                this.is_playing = false
            });
                this.wavesurfer.load(this.URL+'api/get_wav/'+idx);
                 var c_url = this.fft_url.split('?') ;
               
            */
                this.aurl = this.URL+'api/get_wav/m2_water_leak/'+idx ;
               // this.fft_url = this.URL+"api/get_fft/"+idx;
               this.fft_url = this.URL+"api/get_fft/m2_water_leak/"+idx+'#'+this.vchart;
             
        },
        apply() {
            
             //   if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
           // }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1 && sessionStorage.m_id !== this.properties.worker){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            
            let params = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                params[keys[i]] = this.properties[keys[i]]
            }
            if(Object.keys(params).length<=0) return
                this.$http.get(this.URL+'api/update_water_leak?idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
            })
            window.close();
          //  this.$emit('leaktable_refresh') ;
           // this.$refs.modal.hide();
        }

    },
       mounted () {
             this.properties = {} ;
          //   var s_aurl = "";
             var s_ffturl = "";
              this.ai_result ="";
             //옆에 메뉴를 없어지게 한다.
            document.getElementById("sidebar").style.display='none';
             document.getElementById("Header").style.display='none';
             document.getElementById("menu2").style.display='none';
             document.getElementById("main").style.paddingLeft ='0px' ;
             document.getElementById("main").style.paddingTop ='0px' ;           
             document.getElementsByClassName("content-wrap").item(0).style.height ='100%' ;
             document.getElementById("page-wrapper").style.minWidth = "500px";
            if(typeof this.$store.setting === "undefined") {
                this.$http.get(this.URL+'api/get_setting?m_id='+sessionStorage.m_id).then((res) => {
                    this.$store.setting = res.data
                })
            }
             this.$http.get(this.URL+'api/get_m2_leak_info?id='+this.$route.query.idx).then((res) => {
                if(res.data.length<=0) return            

              //  let leak_result = res.data.list[0]['leak_result'] ;         
                
             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;
            
                this.properties = res.data.list[0];   
               
               this.ai_result = this.properties['ai_leak_result'] ;
                s_ffturl = res.data.list[0].fft_file_path;

                if(s_ffturl === null || s_ffturl=== undefined)
                  s_ffturl = "/get_fft/m2_water_leak/"+this.$route.query.idx;
                 this.fft_url = this.URL+"api/"+s_ffturl+'#'+this.vchart;

                this.properties_bak = Object.assign({}, this.properties)

                setTimeout(()=>{
                    this.aurl = this.URL+'api/get_wav/m2_water_leak/'+this.$route.query.idx;
                 }, 200)      

             })

                
            //   this.aurl = s_aurl;
            //   this.fft_url = this.URL+"api/"+s_ffturl+'#'+this.vchart;
            //this.$refs.modal.show();
            
          //  this.fft_url = this.URL+"api/get_fft/"+this.$route.query.idx+'?'+this.vchart;
      
      
    },
    components: {
        FFTChart1: FFTChart1
        //FFTChart1_c:FFTChart1_c
    }
}
</script>

<style lang="scss" >
@import "@/assets/scss/base/variables";
// 2022 new

// .facility__label-radio {
//   font-size: 12px;
//   color: #616161;
//   display: inline-block;
//   vertical-align: middle;
//   line-height: 25px;
//   margin-bottom: 0;
//   margin-left: 5px;
//   margin-right: 10px;
// }

// #Header {   display: none; }
// .page-wrapper .page-content {
//      padding: 0;
   
// }
// .facility-info__title {
//     background: white;
// }

// .mb-3 {
//     margin: 1rem!important;
// }
// .nav-tabs {
//      background: white;
//      border-bottom: 1px solid #dee2e6; 
// }
// .tab-content {
//     max-height: 1100px;    
//     overflow-y: hidden;    
//     overflow-x: hidden; 
// }
.facility__label-radio {
  font-size: 12px;
  color: $color-white;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}

#Header {   display: none; }
.page-wrapper .page-content {
     padding: 0;
   
}
.facility-info__title {
    background: $color-content-bg;
}

.mb-3 {
    margin: 1rem !important;
}
.nav-tabs {
     background: $color-content-bg;
    //  border-bottom: 1px solid #dee2e6; 
}
.tab-content {
    max-height: 1100px;    
    overflow-y: hidden;    
    overflow-x: hidden; 
}
</style>
